const routes = {
  root: '/',
  login: '/login',
  register: '/register',
  resetPassword: '/reset-password',
  resetPasswordUpdate: '/reset-password/:token',

  dashboard: '/dashboard',
  main: '/dashboard/me',
  myInviter: '/dashboard/me/inviter',
  myIncomes: '/dashboard/me/incomes',
  finance: '/dashboard/finance',
  transfer: '/dashboard/finance/transfer',
  history: '/dashboard/finance/history',
  deposit: '/dashboard/finance/deposit',
  withdraw: '/dashboard/finance/withdraw',
  marketing: '/dashboard/marketing',
  mainMarketing: '/dashboard/marketing/main',
  boomboxMarketing: '/dashboard/marketing/boombox',
  topboxMarketing: '/dashboard/marketing/topbox',
  topboxMarketingDesk: '/dashboard/marketing/topbox/:deskId',
  topboxMarketingDeskPlace: '/dashboard/marketing/topbox/:deskId/place/:placeId',
  topboxMarketingDeskStats: '/dashboard/marketing/topbox/:deskId/stats',
  boomboxMarketingDeskStats: '/dashboard/marketing/boombox/:deskId/stats',
  boomboxMarketingDesk: '/dashboard/marketing/boombox/:deskId',
  boomboxMarketingDeskPlace: '/dashboard/marketing/boombox/:deskId/place/:placeId',
  tictacMarketing: '/dashboard/marketing/tictac',
  tictacMarketingDesk: '/dashboard/marketing/tictac/:deskId',
  tictacMarketingDeskPlace: '/dashboard/marketing/tictac/:deskId/place/:placeId',
  tictacMarketingDeskStats: '/dashboard/marketing/tictac/:deskId/stats',
  klondikeMarketing: '/dashboard/marketing/klondike',
  klondikeMarketingDesk: '/dashboard/marketing/klondike/:deskId',
  klondikeMarketingDeskPlace: '/dashboard/marketing/klondike/:deskId/place/:placeId',
  klondikeMarketingDeskStats: '/dashboard/marketing/klondike/:deskId/stats',
  megaBoxMarketing: '/dashboard/marketing/megabox',
  megaBoxMarketingDesk: '/dashboard/marketing/megabox/:deskId',
  megaBoxMarketingDeskPlace: '/dashboard/marketing/megabox/:deskId/place/:placeId',
  megaBoxMarketingDeskStats: '/dashboard/marketing/megabox/:deskId/stats',

  speedBoxMarketing: '/dashboard/marketing/speedbox',
  speedBoxMarketingDesk: '/dashboard/marketing/speedbox/:deskId',
  speedBoxMarketingDeskPlace: '/dashboard/marketing/speedbox/:deskId/place/:placeId',
  speedBoxMarketingDeskStats: '/dashboard/marketing/speedbox/:deskId/stats',

  mainMarketingDesk: '/dashboard/marketing/main/:deskId',
  mainMarketingDeskPlace: '/dashboard/marketing/main/:deskId/place/:placeId',
  mainMarketingDeskStats: '/dashboard/marketing/main/:deskId/stats',
  structure: '/dashboard/structure',
  magicBox: '/dashboard/magic-box',
  magicBoxLot: '/dashboard/magic-box/:lotId',
  notifications: '/dashboard/notifications',
  documents: '/dashboard/documents',
  reviews: '/dashboard/reviews',
  settings: '/dashboard/settings',

  admin: '/dashboard/admin',
  adminUsers: '/dashboard/admin/users',
  adminUser: '/dashboard/admin/users/:userId',
  adminTransactions: '/dashboard/admin/transactions',
  adminTransaction: '/dashboard/admin/transactions/:transactionId',
  adminNotifications: '/dashboard/admin/notifications',
  adminReviews: '/dashboard/admin/reviews',
};

export default routes;
