/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BuyPlacesRequest } from '../models/BuyPlacesRequest';
import type { DeskItemPlaces7 } from '../models/DeskItemPlaces7';
import type { DeskListResponse } from '../models/DeskListResponse';
import type { FoundDeskUser } from '../models/FoundDeskUser';
import type { LineSpeedBoxStatisticsItem } from '../models/LineSpeedBoxStatisticsItem';
import type { PreOrderDeskListItem } from '../models/PreOrderDeskListItem';
import type { SpeedBoxStatistics } from '../models/SpeedBoxStatistics';
import type { UserInstalledClone5 } from '../models/UserInstalledClone5';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SpeedBoxMarketingService {
  /**
   * Gets the list of desks in the SpeedBox marketing for pre-order
   * @returns any Returns the list of desks in the SpeedBox marketing for pre-order
   * @throws ApiError
   */
  public static getSpeedBoxPreOrderDeskList(): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<PreOrderDeskListItem>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/speedbox/pre-order/desk/list',
      errors: {
        403: `Access Denied`,
      },
    });
  }

  /**
   * Buys a buy pre-order in the SpeedBox marketing
   * @param deskId SpeedBox Desk ID
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static buySpeedBoxPreOrder(deskId: number, requestBody: BuyPlacesRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/marketing/speedbox/pre-order/buy/{deskId}',
      path: {
        deskId: deskId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Gets pre-order desk statistics in SpeedBox marketing
   * @param offset Offset
   * @param limit Limit
   * @param line line id
   * @param username Username query
   * @param isBuy Filter for users who buy SpeedBox
   * @returns any Returns desk statistics in SpeedBox marketing
   * @throws ApiError
   */
  public static getSpeedBoxPreOrderStatistics(
    offset?: number,
    limit: number = 12,
    line: number = 1,
    username?: string,
    isBuy?: boolean
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<LineSpeedBoxStatisticsItem>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/speedbox/pre-order/statistics',
      query: {
        offset: offset,
        limit: limit,
        line: line,
        username: username,
        isBuy: isBuy,
      },
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
      },
    });
  }

  /**
   * Gets the list of desks in the SpeedBox marketing
   * @returns any Returns the list of desks in the SpeedBox marketing
   * @throws ApiError
   */
  public static getSpeedBoxDeskList(): CancelablePromise<{
    status: boolean;
    data: DeskListResponse;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/speedbox/desk/list',
      errors: {
        403: `Access Denied`,
      },
    });
  }

  /**
   * Gets the structure of the desk.
   * @param deskId SpeedBox Desk ID
   * @returns any Returns the structure of the desk.
   * @throws ApiError
   */
  public static showSpeedBoxDesk(deskId: number): CancelablePromise<{
    status: boolean;
    data: DeskItemPlaces7;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/speedbox/desk/{deskId}/show',
      path: {
        deskId: deskId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets the desk structure starting from the given place.
   * @param placeId SpeedBox Place ID
   * @returns any Returns the desk structure starting from the given place.
   * @throws ApiError
   */
  public static showSpeedBoxDeskFromPlace(placeId: number): CancelablePromise<{
    status: boolean;
    data: DeskItemPlaces7;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/speedbox/place/{placeId}/show',
      path: {
        placeId: placeId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets the desk structure starting on next level.
   * @param placeId SpeedBox Place ID
   * @returns any Returns the desk structure starting on next level.
   * @throws ApiError
   */
  public static showSpeedBoxNextLevelJump(placeId: number): CancelablePromise<{
    status: boolean;
    data: DeskItemPlaces7;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/speedbox/place/{placeId}/next-level-jump',
      path: {
        placeId: placeId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets the desk structure starting on current level.
   * @param placeId SpeedBox Place ID
   * @returns any Returns the desk structure starting on current level.
   * @throws ApiError
   */
  public static showSpeedBoxCurrentLevelJump(placeId: number): CancelablePromise<{
    status: boolean;
    data: DeskItemPlaces7;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/speedbox/place/{placeId}/current-level-jump',
      path: {
        placeId: placeId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Buys places or clones in SpeedBox marketing with automatic placement in the matrix
   * @param deskId SpeedBox Desk ID
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static buySpeedBoxPlaces(deskId: number, requestBody: BuyPlacesRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/marketing/speedbox/{deskId}/buy/places',
      path: {
        deskId: deskId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Buys a clone in SpeedBox marketing with placement in a given place
   * @param placeId SpeedBox Parent Place ID
   * @param position Place Position
   * @returns void
   * @throws ApiError
   */
  public static buySpeedBoxClone(placeId: number, position: 'L' | 'M' | 'R'): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/marketing/speedbox/buy/clone/{placeId}/{position}',
      path: {
        placeId: placeId,
        position: position,
      },
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Gets a list of my installed clones in SpeedBox marketing.
   * @param deskId SpeedBox Desk ID
   * @param offset Offset
   * @param limit Limit
   * @returns any Returns a list of my installed clones in SpeedBox marketing.
   * @throws ApiError
   */
  public static myInstalledClonesInSpeedBox(
    deskId: number,
    offset?: number,
    limit: number = 50
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<UserInstalledClone5>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/speedbox/desk/{deskId}/my-clones',
      path: {
        deskId: deskId,
      },
      query: {
        offset: offset,
        limit: limit,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets desk statistics in SpeedBox marketing
   * @param offset Offset
   * @param limit Limit
   * @param filterDesk Desk ids
   * @param filterLine Lines
   * @param filterClones Show only clones
   * @param filterPlacesLeft Places before closing
   * @param filterUsername Username query
   * @returns any Returns desk statistics in SpeedBox marketing
   * @throws ApiError
   */
  public static showSpeedBoxStatistics(
    offset?: number,
    limit: number = 12,
    filterDesk?: Array<number>,
    filterLine?: Array<1 | 2 | 3 | 4 | 5>,
    filterClones?: boolean,
    filterPlacesLeft?: Array<1 | 2 | 3>,
    filterUsername?: string
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<SpeedBoxStatistics>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/speedbox/desk/statistics',
      query: {
        offset: offset,
        limit: limit,
        'filter[desk][]': filterDesk,
        'filter[line][]': filterLine,
        'filter[clones]': filterClones,
        'filter[placesLeft][]': filterPlacesLeft,
        'filter[username]': filterUsername,
      },
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
      },
    });
  }

  /**
   * Searches for a user in the desk by username in SpeedBox marketing
   * @param deskId SpeedBox Desk ID
   * @param q Username
   * @param limit
   * @returns any Returns found users on the desk
   * @throws ApiError
   */
  public static searchUserInSpeedBoxMarketing(
    deskId: number,
    q: string,
    limit: number = 10
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<FoundDeskUser>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/speedbox/desk/{deskId}/search/user',
      path: {
        deskId: deskId,
      },
      query: {
        q: q,
        limit: limit,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }
}
