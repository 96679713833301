import React, { useMemo } from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStore } from 'store';

import { Modal } from 'components/feedback';
import { Typography } from 'components/dataDisplay';
import { Button } from 'components/inputs';

import styles from './SpeedBoxLockedDeskModal.module.scss';
import { isNil } from 'lodash';

const SpeedBoxLockedDeskModal: React.FC = () => {
  const {
    speedBoxMarketingStore: { lockedDeskModalConfig, closeLockedDeskModal },
  } = useStore();
  const { t } = useTranslation();

  const handleClose = () => {
    closeLockedDeskModal();
  };

  const { requiredDesk, requiredCategory } = useMemo(() => {
    if (isNil(lockedDeskModalConfig)) return { requiredCategory: '', requiredDesk: '' };

    return {
      requiredDesk: t('speedBoxMarketingPage.card.requirements.first'),
      requiredCategory: 'S1',
    };
  }, [lockedDeskModalConfig, t]);

  return (
    <Modal className={styles.SpeedBoxLockedDeskModal} isOpen={!!lockedDeskModalConfig} onClose={handleClose}>
      <Typography className={styles.title} variant="heading6" element="h2">
        {t('modals.speedBoxLockedDesk.title')}
      </Typography>

      <Typography className={styles.text} element="p">
        {t('modals.speedBoxLockedDesk.text', { category: requiredCategory, desk: requiredDesk })}
      </Typography>

      <Button className={styles.button} onClick={handleClose}>
        {t('modals.speedBoxLockedDesk.button')}
      </Button>
    </Modal>
  );
};

export default observer(SpeedBoxLockedDeskModal);
